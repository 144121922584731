<template>
  <div class="products">
    <div class="product-body">
      <!-- <b-alert v-if="!rows.length" show variant="warning" class="text-center m-2">
        <h5>Bilgi</h5>
        <hr />
        <p>Gösterilecek veri yok</p>
      </b-alert> -->
      <div class="p-2">
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :line-numbers="true"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          :expandOptions="{ enabled: true, selfManaged: true }"
          :expandedRows="[1, 4, 12, 54]"
          styleClass="vgt-table striped bordered"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'urun_tipi'">
              <b-badge variant="" class="d-block mt-1 rounded-0"> {{ urunTipi(props.row.urun_tipi) }} </b-badge>
            </span>
            <span v-else-if="props.column.field === 'misafir'">
              <b-badge variant="" class="d-block mt-1 rounded-0 text-left"> {{ props.row.ad }} {{ props.row.soyad }} </b-badge>
              <b-badge variant="" class="d-block mt-1 rounded-0 text-left">
                {{ props.row.e_mail }}
              </b-badge>
              <b-badge variant="" class="d-block mt-1 rounded-0 text-left">
                {{ props.row.telefon }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'islem_tarihi'">
              <b-badge variant="" class="d-block mt-1 rounded-0 text-left"> {{ props.row.save_date | momentFull }}</b-badge>
            </span>
            <span v-else-if="props.column.field === 'talep_tarihi'">
              <b-badge variant="" class="d-block mt-1 rounded-0 text-left"> {{ props.row.talep_tarihi | momentFull }}</b-badge>
            </span>
            <span v-else-if="props.column.field === 'urun_adi'">
              <b-badge variant="primary" class="rounded-0 font-weight-light" style="font-size: 12px">
                {{ props.row.urun_aciklama }}
              </b-badge>
              <br />

              {{ props.row.ozel_not }}
            </span>
            <span v-else-if="props.column.field === 'pax'">
              <b-badge variant="primary" class="rounded-0 font-weight-light" style="font-size: 12px">
                Yetişkin: {{ props.row.pax.yetiskin }}
              </b-badge>
              <b-badge variant="warning" class="rounded-0 font-weight-light" style="font-size: 12px">
                Çocuk: {{ props.row.pax.cocuk }}
              </b-badge>
              <b-badge variant="warning" class="rounded-0 font-weight-light" style="font-size: 12px">
                Bebek: {{ props.row.pax.bebek }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'statu'">
              <b-badge
                :variant="props.row.statu == 1 ? 'success' : props.row.statu == 2 ? 'warning' : 'danger'"
                class="rounded-0 font-weight-light"
                style="font-size: 12px"
              >
                {{ props.row.statu == 1 ? 'Tamamlandı' : props.row.statu == 2 ? 'Beklemede' : 'İptal Edildi' }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'action'" class="float-right px-1">
              <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret menu-class="w-100">
                <template #button-content> <i class="fad fa-ellipsis-v-alt"></i> </template>

                <b-dropdown-item
                  :to="{
                    name: 'rezervasyon-guncelle',
                    params: { k_no: props.row.k_no },
                  }"
                >
                  <i class="fad fa-edit" /> Tamamla</b-dropdown-item
                >

                <b-dropdown-item @click="handlerRemove(props.row.k_no)"> <i class="fad fa-trash" /> Sil</b-dropdown-item>
              </b-dropdown>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="expanded-row" slot-scope="props">
            <div>
              {{ props.row }}
            </div>
          </template>
          <template slot="pagination-bottom">
            <Pagination
              :urunler="rows"
              :count="count"
              @handlerPageSizeChange="handlerPageSizeChange($event)"
              @handlerPageChange="handlerPageChange($event)"
            />
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import { VueGoodTable } from 'vue-good-table';
import { useToast } from 'vue-toastification/composition';
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api';

import Pagination from '../component/listele/Pagination.vue';
import { useRouter } from '@/libs/utils';
export default defineComponent({
  components: {
    vSelect,
    VueGoodTable,
    Pagination,
  },
  props: {
    filtered: [Boolean],
    itemView: [String],
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { route, router } = useRouter();
    expo.pageLength = ref(20);
    expo.perPageOptions = ref([10, 20, 50, 100]);
    expo.columns = ref([
      {
        label: 'Tipi',
        field: 'urun_tipi',
        width: '6%',
      },
      {
        label: 'Misafir Bil.',
        field: 'misafir',
        width: '12%',
      },
      {
        label: 'İşlem Tarihi',
        field: 'islem_tarihi',
        width: '9%',
      },
      {
        label: 'Talep Edilen Tarih',
        field: 'talep_tarihi',
        width: '10%',
      },
      {
        label: 'Ürün Adı',
        field: 'urun_adi',
      },
      {
        label: 'Pax',
        field: 'pax',
        width: '10%',
        tdClass: 'text-right',
      },
      {
        label: 'Statü',
        field: 'statu',
        width: '10%',
        tdClass: 'text-right',
      },
      {
        field: 'action',
        width: '3%',
      },
    ]);

    expo.rows = ref([]);
    expo.count = ref(0);

    expo.urunTipi = computed(() => {
      const urun = {
        villa: 'Villa',
        yat: 'Yat',
        tur: 'Tur',
        arac_kira: 'Araç Kira',
        transfer: 'Transfer',
        saglik_turizmi: 'Sağlık Turizmi',
        dugun_organizasyon: 'Düğün Organizasyonu',
      };

      return (val) => urun[val];
    });

    expo.handlerFetchAllData = async () => {
      context.emit('show', true);
      context.emit('pageTitle', 'Ozel Talepler');

      route.value.query.page = route.value.query.page ? parseInt(route.value.query.page) : 1;
      route.value.query.page_size = route.value.query.page_size ? parseInt(route.value.query.page_size) : 20;

      await store.dispatch('ozelTalepListele', route.value.query).then((res) => {
        expo.rows.value = res.data.data;
        expo.count.value = res.data.count;
        context.emit('total', `${expo.count.value} kayıttan ${expo.rows.value.length} `);
        context.emit('show', false);
        // context.emit('filtered', false);
      });
    };

    expo.handlerPageSizeChange = (event) => {
      router.push({
        query: { ...route.value.query, page_size: event },
      });
    };

    expo.handlerPageChange = (event) => {
      router.push({
        query: { ...route.value.query, page: event },
      });
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('ozelTalepSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast.error('Silme Başarılı', { position: 'bottom-left' });
            }
          });
        }
      });
    };

    watch(
      route,
      (val) => {
        expo.handlerFetchAllData();
      },
      { immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.products {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  .product-header {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ebe9f1;
    padding: 10px 5px;
    background: #fff;
    transition: all 0.2s ease-in-out;
    z-index: 5;
    &.active {
      opacity: 1;
      visibility: visible;
      transition: all 0.2s ease-in-out;
    }
  }
}
</style>
